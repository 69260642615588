<template>
  <div id="software">
    <!-- Top Panel w/breacrumb TODO: MOVE INLINE STYLE TO SCSS-->
    <div v-if="software" class="jumbotron jumbotron-fluid texture texture-green sub-header header-padding"
         :style="{backgroundImage: `linear-gradient(180deg, rgba(65, 65, 65, 0.9) 0%, rgba(65, 65, 65, 0.9) 100%), url('${SPENV.S3_URL}/images/software-jumbotrons/${software.id}.jpg')`}">
      <div class="container">
        <Breadcrumbs :breadcrumb-prop="breadcrumb" />
        <h1 class="display-4 mt-2">
          {{ software.header }}
        </h1>
        <p class="lead">
          {{ software.description }}
        </p>
        <div v-if="!user || !user.isMember" class="mt-4">
          <p>{{ software.call_to_action }}</p>
          <a class="btn btn-primary ghost" :href="SPENV.WWW_URL + '/signup/plan?return_url=' + location">Sign
            up</a>
        </div>
      </div>
    </div>

    <div v-if="software" class="container">
      <div class="row">
        <div class="col-sm-12 col-md-9 col-lg-10 offset-md-3 offset-lg-2">
          <div class="mt-4 mb-4">
            <h2>Explore the Library of {{ software.name }} Training Courses</h2>
          </div>
        </div>
      </div>
      <course-list
        v-if="software.isCourseListLoaded"
        :software="software"
        :is-library="true"
        :route-to="routeTo" />
    </div>

    <sp-loader v-if="isLoading" />
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';
import CourseList from '@/components/CourseList';
import computeMetaData from '@/utils/seoTable.js';
import Breadcrumbs from '@/components/Breadcrumbs';

export default {
  name: 'Software',
  components: {
    'course-list': CourseList,
    Breadcrumbs,
  },
  extends: BaseComponent,
  data() {
    return {
      softwareSlug: null,
      breadcrumb: [],
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.softwareSlug = to.params.swslug;
    this.loadSoftware();
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.softwareSlug = to.params.swslug;
      vm.createBreadcrumb();
      vm.loadSoftware();
      vm.getMetaData();
    });
  },
  metaInfo() {
    return computeMetaData(
      this.metaData,
      'software',
      this.software,
      this.$route.params
    );
  },
  computed: {
    software() {
      return this.$store.getters['software/getSoftwareBySlug'](
        this.softwareSlug,
      );
    },
    metaData() {
      return this.$store.getters['software/getMetaData'];
    },
    location() {
      return window.location;
    },
  },
  watch: {
    'software': function () {
      this.createBreadcrumb();
    },
  },
  methods: {
    routeTo(course) {
      return {name: 'course', params: {courseslug: course.slug}};
    },
    /**
     * Get API Meta Data
     * 'type' must correspond to Vue Course/Software page
     * 'slug' is the route param for the corresponding level above
     */
    getMetaData() {
      this.$store.dispatch('software/loadMetaData', {type: 'software', slug: this.$route.params.swslug});
    },
    createBreadcrumb() {
      if (this.software) {
        this.breadcrumb = [
          {
            label: 'Library',
            route: {
              name: 'library',
            },
          },
          {
            label: this.software.name,
            route: {
              name: 'software',
              params: {
                swslug: this.softwareSlug,
              },
            },
          },
        ];
      }
    },
    /**
     * sr 4/6/18
     * Load software object from the api if we don't have it and its courses
     */

    loadSoftware() {
      return new Promise(resolve => {
        this.isLoading = true;
        // if the full software object hasn't been retrieved, retrieve it
        let software = this.$store.getters['software/getSoftwareBySlug'](this.softwareSlug);
        if (software && software.isCourseListLoaded) {
          // already loaded, don't load again
          this.isLoading = false;
          resolve();
        } else {
          // load the software
          this.$store.dispatch('software/loadSoftwareBySlug', this.softwareSlug).then(
            () => {
              this.createBreadcrumb();
            },
            (error) => {
              console.log(error);
              // If software is not found return to library jhamm 6/8/18
              this.$router.push({name: 'library'}).catch(err => {
              });
            },
          )
            .then(
              () => {
                this.isLoading = false;
              });
        }
      });
    },
  },
};
</script>
