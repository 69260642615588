<template>
  <ol class="breadcrumb pt-0 pb-0 pl-0 mb-0">
    <li v-for="(breadcrumb, $index) in breadcrumbs" :key="breadcrumb.label" class="breadcrumb-item">
      <!-- Each step in the breadcrumb is built dependantly with a v-if corresponding to value in the object-->
      <router-link :to="breadcrumb.route">
        {{ breadcrumb.label }}
      </router-link>
      <span v-if="$index < breadcrumbs.length - 1" class="pl-1"><font-awesome-icon icon="angle-right" /></span>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    breadcrumbProp: {
      type: Array,
      default: function () {
        return [];
      }
    },
  },
  data() {
    return {

    };
  },
  computed: {
    /**
     * jhamm 4/30/18
     * Get an array of breadcrumbs based on props passed in from parent component
     */
    breadcrumbs() {
      return this.breadcrumbProp;
    },
  },
};
</script>
