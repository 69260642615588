export default function computeMetaData(
  metaData,
  type = null,
  spContent,
  routerParams = null,
) {

  let title, canonical;

  // Declare default values used on multiple properties
  let defaultLogo = process.env.SPENV.S3_URL + '/images/logos/sp-logo.png';
  let defaultDescription = 'SolidProfessor provides training courses for engineers; Solidworks, Inventor, Fusion 360 and more';
  let defaultTitle = 'Engineering Tutorials and Training Courses';

  // Declare Defaults
  let description = 'With more than 5,000 interactive lessons, the SolidProfessor Library has the training courses engineers need. Learn from continuously updated, expert-led tutorials. Practice your skills with hands-on exercises. Obtain certifications to take your career to the next level. Never stop learning and improving.';
  let viewport = 'width=device-width, initial-scale=1';
  let ogTitle = defaultTitle;
  let ogSitename = 'SolidProfessor.com';
  let ogType = 'website';
  let ogUrl = process.env.SPENV.WWW_URL + '/tutorials/';
  let ogImage = defaultLogo;
  let ogDescription = defaultDescription;
  let twitterCard = 'summary';
  let twitterSite = 'SolidProfessor Tutorials and Training Courses';
  let twitterDescription = defaultDescription;
  let twitterCreator = '@solidprofessor';
  let twitterImageSrc = defaultLogo;
  let twitterTitle = 'Tutorials and Training Courses';
  let schemaName = defaultTitle;
  let schemaDescription = defaultDescription;
  let schemaImage = defaultLogo;
  let keywords = 'training, tutorial, certification, courses, video, learn, online, how to, Solidworks, Inventor, Fusion 360';

  let processMetaData = function(){
    if (metaData.page_title) title = metaData.page_title;
    if (metaData.canonical) canonical = metaData.canonical;
    if (metaData.description) description = metaData.description;
    if (metaData.keywords) keywords = metaData.keywords;
    if (metaData.viewport) viewport = metaData.viewport;

    // OpenGraph data (Used by Facebook to generate in-page tiles)
    if (metaData.og_title) ogTitle = metaData.og_title;
    if (metaData.og_sitename) ogSitename = metaData.og_sitename;
    if (metaData.og_type) ogType = metaData.og_type;
    if (metaData.og_url) ogUrl = metaData.og_url;
    if (metaData.og_image) ogImage = metaData.og_image;
    if (metaData.og_description) ogDescription = metaData.og_description;

    // Twitter card
    if (metaData.twitter_card) twitterCard = metaData.twitter_card;
    if (metaData.twitter_site) twitterSite = metaData.twitter_site;
    if (metaData.twitter_description) twitterDescription = metaData.twitter_description;
    if (metaData.twitter_creator) twitterCreator = metaData.twitter_creator;
    if (metaData.twitter_image_src) twitterImageSrc = metaData.twitter_image_src;
    if (metaData.twitter_title) twitterTitle = metaData.twitter_title;

    // Google / Schema.org markup:
    if (metaData.schema_name) schemaName = metaData.schema_name;
    if (metaData.schema_description) schemaDescription = metaData.schema_description;
    if (metaData.schema_image) schemaImage = metaData.schema_image;
  };

  let processContent = function() {
    title = defaultTitle;
    if (type === 'software'){
      title = spContent.name + ' Tutorials and Training Courses';
    } else if (type === 'course') {
      title = spContent.name + ' Tutorial Videos';
    }
    if (type === 'software' || type === 'course') {
      if (spContent.description) description = spContent.description;
      keywords = 'training, tutorial, certification, courses, video, learn, online, how to, ' + spContent.name;
    }
    if (routerParams) {
      canonical = [
        process.env.SPENV.WWW_URL,
        'tutorials',
        routerParams.swslug,
        routerParams.courseslug,
        routerParams.lessonid
      ].join('/');
    }
    ogTitle = title;
    ogDescription = spContent.description;
    twitterSite = title;
    twitterDescription = spContent.description;
    schemaName = title;
    schemaDescription = description;
  };

  if (metaData != null && Object.keys(metaData).length) {
    processMetaData();
  } else if (spContent != null && Object.keys(spContent).length) {
    processContent();
   } else {
    // No SP Content, bounce!
    return {};
  }

  let metadataresponse =  {
    title: title,
    link: [{rel: 'canonical', href: canonical}],
    meta: [
      {name: 'viewport', content: viewport},
      {hid: 'description', name: 'description', content: description},
      {hid: 'keywords', name: 'keywords', content: keywords},

      // OpenGraph data (Used by Facebook to generate in-page tiles)
      {property: 'og:title', content: ogTitle},
      {property: 'og:site_name', content: ogSitename},
      {property: 'og:type', content: ogType},
      {property: 'og:url', content: ogUrl},
      {property: 'og:image', content: ogImage},
      {property: 'og:description', content: ogDescription},

      // Twitter card
      {name: 'twitter:card', content: twitterCard},
      {name: 'twitter:site', content: twitterSite},
      {name: 'twitter:title', content: twitterTitle},
      {name: 'twitter:description', content: twitterDescription},
      {name: 'twitter:creator', content: twitterCreator},
      {name: 'twitter:image:src', content: twitterImageSrc},

      // Google / Schema.org markup:
      {itemprop: 'name', content: schemaName},
      {itemprop: 'description', content: schemaDescription},
      {itemprop: 'image', content: schemaImage},
    ],
  };

  return metadataresponse;
}
