<template>
  <div id="course-list">
    <div class="software">
      <div class="d-block d-md-none text-right mb-3">
        <b-link class="btn-link text-muted" href="javascript:void(0)" @click="showFilters = !showFilters">
          <font-awesome-icon icon="filter" fixed-width /> Filter
        </b-link>
      </div>
      <b-row class="mb-4">
        <b-collapse id="collapse" v-model="showFilters" class="d-md-block col-md-3 col-lg-2 mb-3">
          <b-row>
            <b-col
              v-for="filterGroup in filterGroups"
              :key="filterGroup.id"
              cols="6"
              md="12">
              <div class="form-group">
                <div class="mb-2" style="font-weight:600">
                  {{ filterGroup.label }}
                </div>
                <div v-for="filter in filterGroup.displayedFilters" :key="filter.id" class="mb-2">
                  <span class="check-input pointer" :class="{ disabled: filter.disabled }" @click="toggleFilter(filter, !filter.selected)">
                    <font-awesome-icon
                      class="mr-2" size="lg"
                      :icon="filter.selected? ['fas', 'check-square']: ['fas', 'square']"
                      :class="{ 'text-primary': filter.selected }" />
                    {{ filter.displayText }}
                  </span>
                </div>
                <div>
                  <small v-if="filterGroup.limitTo && filterGroup.filters.length > filterGroup.limitTo">
                    <b-link
                      v-if="filterGroup.displayedFilters.length <= filterGroup.limitTo"
                      href="javascript:void(0)"
                      class="btn-link"
                      @click="showAllFilters(filterGroup)">Show more</b-link>
                    <b-link
                      v-else
                      href="javascript:void(0)"
                      class="btn-link"
                      @click="showLessFilters(filterGroup)">Show less</b-link>
                  </small>
                </div>
              </div>
            </b-col>
            <b-col v-if="isLibrary && library" cols="6" md="12">
              <div class="mb-2 mt-2" style="font-weight:600">
                Other Topics
              </div>
              <div v-for="hrefSoftware in library.slice(0, 11)" :key="hrefSoftware.id" class="mb-1">
                <div v-if="hrefSoftware.name !== software.name">
                  <router-link class="mb-1 sidebar-text" :to="{name: 'software', params: { swslug: hrefSoftware.slug }}">
                    {{ hrefSoftware.name }}
                  </router-link>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
        <b-col sm="12" md="9" lg="10">
          <b-row>
            <b-col cols="7" lg="8" xl="9">
              <div class="pull-left">
                <div>
                  {{ filteredAndSortedCourses.length }} results in {{ software.name }}<span v-if="selectedFilters.length > 0"> matching:</span>
                </div>
                <div>
                  <span
                    v-for="filter in selectedFilters"
                    :key="filter.id"
                    class="badge badge-light font-weight-normal pointer mr-2 my-1"
                    style="font-size:1em"
                    @click="toggleFilter(filter, false)">{{ filter.displayText }}
                    <small class="align-top"><font-awesome-icon icon="times" fixed-width /></small>
                  </span>
                </div>
              </div>
            </b-col>
            <b-col cols="5" lg="4" xl="12">
              <div class="form-inline float-right">
                <label for="exampleFormControlSelect1" class="mr-1"><small>Sort by:</small></label>
                <select
                  id="exampleFormControlSelect1"
                  v-model="sortBy"
                  class="form-control form-control-sm"
                  @change="limitCoursesTo = 20">
                  <option v-for="sorter in sorters" :key="sorter.label" :value="sorter">
                    {{ sorter.label }}
                  </option>
                </select>
              </div>
            </b-col>
          </b-row>
          <ul class="list-group-shadow list-group">
            <router-link
              v-for="course in displayedCourses"
              :key="course.id"
              :to="routeTo(course)"
              append
              class="list-group-item list-group-item-action mt-3 mb-3"
              @click.native="onClick(course, $event)">
              <b-row class="media p-3">
                <b-col cols="12" lg="3" class="text-center align-self-center mb-2">
                  <b-img-lazy
                    class="mr-3 course-thumbnail"
                    :src="SPENV.S3_URL + '/images/course-thumbs/' +course.id +'.jpg'"
                    alt="Course thumbnail"
                    :onerror="placeHolderImage()" />
                </b-col>
                <b-col cols="12" class="media-body m-2">
                  <h4 v-if="course.version_display" class="font-weight-semibold mt-0 mb-1">
                    {{ course.version_display }} {{ course.name }}
                  </h4>
                  <h4 v-if="!course.version_display" class="font-weight-semibold mt-0 mb-1">
                    {{ course.year }} {{ course.name }}
                  </h4>
                  <div v-if="course.description" v-line-clamp:24="'3'" class="text-muted mb-2 preserve-word">
                    <div>{{ course.description | striphtml }}</div>
                  </div>
                  <div class="align-text-bottom">
                    <span class="badge badge-light text-muted">{{ duration(course.duration) }}</span>
                    <span v-if="isLibrary && course.cert && course.cert.id">
                      <span v-if="course.cert.completion_date && course.cert.status === 'earned'" class="badge badge-primary">
                        Certificate earned
                      </span>
                      <span v-else class="badge badge-light text-muted">
                        Certificate available
                      </span>
                    </span>
                    <span class="badge badge-light text-muted">
                      <span v-if="isLibrary">{{ course.num_viewed }} of {{ course.num_lessons }} complete
                      </span>
                      <span v-else> {{ course.num_lessons }} lessons </span>
                    </span>
                  </div>
                </b-col>
              </b-row>
            </router-link>
            <li v-if="filteredAndSortedCourses.length === 0" class="list-group-item">
              No results are available based on these filters.
            </li>
            <InfiniteLoading ref="infiniteLoading" :identifier="infiniteLoaderIdentifier" spinner="bubbles"
                             @infinite="displayMoreCourses">
              <span slot="no-results" />
              <span slot="no-more" />
            </InfiniteLoading>
          </ul>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';
import InfiniteLoading from 'vue-infinite-loading';
import _ from 'lodash';

export default {
  name: 'CourseList',
  components: {
    InfiniteLoading
  },
  extends: BaseComponent,
  props: {
    software: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isLibrary: Boolean,
    onClick: {
      // on course click handler
      type: Function,
      default: function() {
        return {};
      }
    },
    routeTo: {
      // a function that takes a course object and returns a route object of where to navigate to on click
      type: Function,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      limitCoursesTo: 20,
      limitYearsTo: 5,
      sortBy: null,
      sorters: null,
      filteredCourses: [],
      filterGroups: [],
      selectedFilters: [],
      showFilters: false,
      breadcrumb: [],
      infiniteLoaderIdentifier: +new Date(),
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.init();
    next();
  },
  computed: {
    /**
     * @author srogalsky
     * 4/18/18
     * Computed property that calls sort courses whenever
     * filteredCourses is updated
     * @returns {array}
     */
    filteredAndSortedCourses() {
      return this.sortCourses(this.filteredCourses);
    },
    /**
     * @author srogalsky
     * 4/18/18
     * Computed property that displays a limited number of courses
     * Called when filteredAndSortedCourses or limitCoursesTo changes
     * @returns {array}
     */
    displayedCourses() {
      return this.filteredAndSortedCourses.slice(0, this.limitCoursesTo);
    },
    library() {
      return this.$store.getters['header/getLibrary'];
    }
  },
  watch: {
    'software': function(val, oldVal) {
      this.filteredCourses = val.courses;
    }
  },
  created: function() {
    this.init();
  },
  methods: {
    /**
     * Initialize software data from store or api jhamm 5/7/18
     */
    init() {
      // initialize filters based on courses
      // more filterGroups can be added here
      this.filterGroups = [
        {
          id: 0,
          label: 'Version',
          prop: 'year',
          filters: this.generateYearFilters(),
          limitTo: 5
        }
      ];
      if (this.isLibrary) {
        this.filterGroups.push({
          id: 1,
          label: 'Type',
          prop: 'courseType',
          filters: this.generateTypeFilters()
        });
      }
      // initially set how many filters should be shown
      for (let i = 0; i < this.filterGroups.length; i++) {
        this.showLessFilters(this.filterGroups[i]);
      }
      this.filteredCourses = this.software.courses; // show all courses on component load

      /**
       * sr 4/18/18
       * Sort the courses by forder but chunk them out
       * In other words, display by the latest version of each course in the recommended order,
       * followed by the next latest version of each course in the recommended order, etc.
       * @param {array} arr array of courses to sort on
       * @returns {array}
       */
      let recommended = function(arr) {
        let output = [];
        // initialize a list containing one course in each family, sorted by forder
        let orderedFamilies = _.orderBy(_.uniqBy(arr, 'family_id'), 'forder');
        // first sort all courses by year
        let coursesNotAdded = _.orderBy(arr, 'year', 'desc');

        // while there are courses to add, iterate through the family list from above
        // and add the first remaining course
        while (coursesNotAdded.length > 0) {
          for (let i = 0; i < orderedFamilies.length; i++) {
            for (let j = 0; j < coursesNotAdded.length; j++) {
              if (
                coursesNotAdded[j].family_id === orderedFamilies[i].family_id
              ) {
                output.push(coursesNotAdded[j]);
                coursesNotAdded.splice(j, 1);
                break;
              }
            }
          }
        }
        return output;
      };

      // populate select dropdown
      this.sorters = [
        {
          label: 'Recommended',
          fn: recommended
        },
        {
          label: 'Newest',
          fn: function(arr) {
            return _.orderBy(arr, 'id', 'desc');
          }
        },
        {
          label: 'Oldest',
          fn: function(arr) {
            return _.orderBy(arr, 'id');
          }
        },
        {
          label: 'Name (A-Z)',
          fn: function(arr) {
            return _.orderBy(arr, ['name', 'year']);
          }
        },
        {
          label: 'Name (Z-A)',
          fn: function(arr) {
            return _.orderBy(arr, ['name', 'year'], 'desc');
          }
        }
      ];
      this.sortBy = this.sorters[0];
    },
    /**
     * sr 4/6/18
     * Increase limit of displayed courses.
     * This is the onscroll callback for infinite-loading
     * @param $state  state of infinite-loading component
     */
    displayMoreCourses($state) {
      this.limitCoursesTo += 20;
      if (this.limitCoursesTo >= this.filteredCourses.length) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },
    /**
     * sr 4/18/18
     * Get the list of year filters
     * @returns {array}
     */
    generateYearFilters() {
      let uniqueYearCourses = _.uniqBy(this.software.courses, 'year');
      let output = [];
      for (let i = 0; i < uniqueYearCourses.length; i++) {
        // Creates `filterTextValue` which will be used as `displayText` in the HTML. Seperates logic from display
        let filterTextValue = 'None';
        if (uniqueYearCourses[i].version_display) {
          filterTextValue = uniqueYearCourses[i].version_display;
        } else if (uniqueYearCourses[i].year) {
          filterTextValue = uniqueYearCourses[i].year;
        }
        output.push({
          id: uniqueYearCourses[i].year,
          sorter: uniqueYearCourses[i].year || ' ' + filterTextValue,
          val: uniqueYearCourses[i].year ? uniqueYearCourses[i].year : 'None',
          displayText: filterTextValue,
          selected: false,
          disabled: false
        });
      }

      return _.orderBy(output, 'sorter', 'desc');
    },
    /**
     * sr 4/18/18
     * Get the list of course type filters
     * @returns {array}
     */
    generateTypeFilters() {
      let uniqueTypes = _.uniqBy(this.software.courses, 'courseType');
      let output = [];
      for (let i = 0; i < uniqueTypes.length; i++) {
        let type = {
          id: uniqueTypes[i].courseType,
          selected: false,
          disabled: false
        };
        switch (type.id) {
        case 1:
          type.val = 'Courses';
          type.displayText = 'Courses';
          break;
        case 3:
          type.val = 'Case studies';
          type.displayText = 'Case studies';
          break;
        case 4:
          type.val = 'Tips & tricks';
          type.displayText = 'Tips & tricks';
          break;
        case 5:
          type.val = 'Workshops';
          type.displayText = 'Workshops';
          break;
        default:
          type.val = 'Other';
          type.displayText = 'Other';
          break;
        }
        output.push(type);
      }
      return output;
    },
    /**
     * sr 4/18/18
     * Reduce the length of displayed filters to limitTo for a filter group
     * @param {object} filterGroup  the filter group being updated
     */
    showLessFilters(filterGroup) {
      if (filterGroup.limitTo) {
        filterGroup.displayedFilters = filterGroup.filters.slice(0, filterGroup.limitTo);
        this.assignFilterGroup(filterGroup);
      } else {
        this.showAllFilters(filterGroup);
      }
    },
    /**
     * sr 4/18/18
     * Set displayed filters to show all for a filter group
     * @param {object} filterGroup  the filter group being updated
     */
    showAllFilters(filterGroup) {
      filterGroup.displayedFilters = filterGroup.filters;
      this.assignFilterGroup(filterGroup);
    },
    /**
     * sr 4/18/18
     * Since reassigning an array element is non-reactive,
     * use this method when updating a filterGroup
     * @param {object} filterGroup the filter group being updated
     */
    assignFilterGroup(filterGroup) {
      for (let i = 0; i < this.filterGroups.length; i++) {
        if (filterGroup.id === this.filterGroups[i].id) {
          this.$set(this.filterGroups, i, filterGroup);
          break;
        }
      }
    },
    /**
     * sr 4/18/18
     * Select or deselect a filter
     * Update course filtering
     * @param {object} filter
     * @param {bool} selected
     */
    toggleFilter(filter, selected) {
      filter.selected = selected;
      // Add to or remove from selectedFilters array
      if (selected) {
        this.selectedFilters.push(filter);
      } else {
        for (let i = 0; i < this.selectedFilters.length; i++) {
          if (this.selectedFilters[i].id === filter.id) {
            this.selectedFilters.splice(i, 1);
            break;
          }
        }
      }
      this.limitCoursesTo = 20; // set to initial value
      // https://peachscript.github.io/vue-infinite-loading/#!/getting-started/with-filter

      this.resetInfiniteLoader();
      this.setFilteredCourses();
    },
    /**
     * Reset the Infinite Loader as per docs
     * https://github.com/PeachScript/vue-infinite-loading/releases   (release 2.4)
     */
    resetInfiniteLoader() {
      this.infiniteLoaderIdentifier += 1;
    },
    /**
     * sr 4/18/18
     * Redraw the list of courses based on selected filters
     * Update the disabled properties on each filter
     */
    setFilteredCourses() {
      this.filteredCourses = [];

      /**
       * sr 4/18/18
       * Check if a course matches any of the filters
       * Used internally in setFilteredCourses
       * @param {object} course course object being filtered
       * @param {string} courseProp the property being filtered on
       * @param {array} filters
       * @returns {bool}
       */
      let matchesFilter = function(course, courseProp, filters) {
        let matches = true; // if no filters are selected, it's a match
        for (let i = 0; i < filters.length; i++) {
          if (filters[i].selected) {
            matches = false; // if one filter is selected, initially set to false
            if (filters[i].id === course[courseProp]) {
              // if any selected filter matches, return true
              matches = true;
              break;
            }
          }
        }
        return matches;
      };

      /*
       * for each filter group, instantiate an array that will contain
       * all courses that match the filter group
       */
      let filterGroupMatches = [];
      for (let i = 0; i < this.filterGroups.length; i++) {
        filterGroupMatches[i] = [];
      }
      for (let i = 0; i < this.software.courses.length; i++) {
        let matchesAllFilterGroups = true;
        for (let j = 0; j < this.filterGroups.length; j++) {
          // does the course match this filter group?
          let match = matchesFilter(this.software.courses[i], this.filterGroups[j].prop, this.filterGroups[j].filters);
          if (match) {
            filterGroupMatches[j].push(this.software.courses[i]);
          }
          matchesAllFilterGroups = matchesAllFilterGroups && match;
        }
        if (matchesAllFilterGroups) {
          // if a course matches all filter groups, display it
          this.filteredCourses.push(this.software.courses[i]);
        }
      }

      /*
       * For each filter in each filter group,
       * check that the filter has a matching course in every
       * other filter group.  If not, then it is disabled
       */
      for (let i = 0; i < this.filterGroups.length; i++) {
        for (let j = 0; j < this.filterGroups[i].filters.length; j++) {
          if (this.filterGroups[i].filters[j].selected) {
            this.filterGroups[i].filters[j].disabled = false;
          } else {
            let inAllMatchedGroups = true;
            for (let k = 0; k < filterGroupMatches.length; k++) {
              if (i !== k) {
                let hasMatchingCourse = false;
                for (let l = 0; l < filterGroupMatches[k].length; l++) {
                  if (
                    this.filterGroups[i].filters[j].id ===
                    filterGroupMatches[k][l][this.filterGroups[i].prop]
                  ) {
                    hasMatchingCourse = true;
                    break;
                  }
                }
                inAllMatchedGroups = inAllMatchedGroups && hasMatchingCourse;
              }
            }
            this.filterGroups[i].filters[j].disabled = !inAllMatchedGroups;
          }
        }
      }
    },
    /**
     * Get hours and minutes of a duration
     * @param {int} seconds course duration
     * @returns {string}
     */
    duration(seconds) {
      let hours = parseInt(seconds / 3600);
      let secondsPastHour = seconds % 3600;
      let minutes = parseInt(secondsPastHour / 60);
      return hours + 'h ' + minutes + 'm';
    },
    /**
     * @author srogalsky
     * 4/18/18
     * Calls the selected sort function
     * on an array of courses
     * @param {array} courses
     * @returns {array}
     */
    sortCourses(courses) {
      return this.sortBy.fn(courses);
    },
    /**
     * Place holder image for courses which do not have image thumbs
     */
    placeHolderImage () {
      let errorString = '';
      errorString += 'this.onerror=null;';
      errorString += 'this.src=\'' + this.SPENV.S3_URL + '/images/course-thumbs/placeholder_16.jpg\'';
      return errorString;
    }
  }
};
</script>
